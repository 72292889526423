import React, { Component } from 'react'
import Link from '../utils/link'

import { YouTube, Instagram, Facebook, Phone } from './icons'
import Logo from '../assets/images/logo.svg'

class Header extends Component {

  state = {
    offCanvas: false,
    scrolled: false,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollDetect)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollDetect)
  }

  onScroll = () => {
    this.setState({
      scrolled: window.scrollY > 1,
    })
  }

  scrollDetect = () => {
    window.clearTimeout( this.isScrolling )
    this.isScrolling = setTimeout(this.onScroll, 0);
  }

  _hideOffCanvas = () => {
    this.setState({ offCanvas: false })
  }

  _toggleOffCanvas = () => {
    this.setState({ offCanvas: !this.state.offCanvas })
  }

  render() {

    let { offCanvas, scrolled } = this.state

    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active'
    }

    let headerClass = 'header'
    if (scrolled) headerClass += ' header--scrolled'
    if (offCanvas) headerClass += ' header--open'

    return (
      <>
        <header className={headerClass}>
          <div className='header__announcement'>
            <Link to='tel:+61422658977'>Want to speak directly with someone <u>Call Now</u></Link>
          </div>
          <div className='header__inner'>
            <Link to='/' title='Mara+Co' className='header__logo' {...props}>
              <img src={Logo} alt='Lake Eildon Boat Sales' />
              <div className='header__logo-text'>Lake Eildon Boat Sales</div>
            </Link>
            <nav className='header__nav'>
              <ul>
                <li><Link to='/buy/' {...props}>Buy</Link></li>
                <li><Link to='/sell/' {...props}>Sell</Link></li>
                <li><Link to='/about-us/' {...props}>About</Link></li>
                <li><Link to='/contact/' {...props}>Contact</Link></li>
                <li className='call-now'><Link to='tel:+61422658977'>0422 658 977</Link></li>
              </ul>
            </nav>
            <button onClick={this._toggleOffCanvas} className={ offCanvas ? 'header__hamburger active' : 'header__hamburger' }>
              <span className='lines'></span>
            </button>
          </div>
        </header>
        <div className={`off-canvas ${ offCanvas && 'off-canvas--active' } ${ scrolled && 'off-canvas--scrolled' }`}>
          <div className='off-canvas__inner'>
            <nav className='off-canvas__nav'>
              <ul>
                <li><Link to='/buy/' {...props}>Buy</Link></li>
                <li><Link to='/sell/' {...props}>Sell</Link></li>
                <li><Link to='/about-us/' {...props}>About Us</Link></li>
                <li><Link to='/contact/' {...props}>Contact</Link></li>
                <li className='call-now'><Link to='tel:+61422658977'>0422 658 977</Link></li>
              </ul>
            </nav>
          </div>
        </div>
      </>
    )
  }
}

export default Header
