import React, { Component } from 'react'
import Link from '../utils/link'

import Logo from '../assets/images/logo.svg'
import { YouTube, Instagram, Facebook } from './icons'
import Newsletter from './newsletter'

class Footer extends Component {
  render() {
    return (
      <footer className='footer'>
        <div className='footer__inner'>
          <div className='footer__logo'>
            <Link to='/' className='footer__logo'><img src={Logo} alt='Lake Eildon Boat Club' /></Link>
          </div>
          <div className='footer__cols'>
            <div className='footer__col'>
              <ul>
                <li><Link to='/buy'>Buy</Link></li>
                <li><Link to='/sell'>Sell</Link></li>
                <li><Link to='/about-us'>About Us</Link></li>
                <li><Link to='/contact'>Contact</Link></li>
              </ul>
            </div>
            <div className='footer__col'>
              <ul>
                <li>
                  <Link to='/contact'>Sugarloaf Road,<br />
                  Eildon, Victoria 3713</Link>
                </li>
                <li>
                  <Link to='tel:+61422658977'>Ph: 0422 658 977</Link>
                </li>
                <li>
                  <Link to='mailto:chris@lakeeildonboatsales.com.au'>chris@lakeeildonboatsales.com.au</Link>
                </li>
              </ul>
              <ul className='footer__social'>
                <li><Link to='https://www.facebook.com/lakeeildonboatsales/'><Facebook color={'#000'} /></Link></li>
                <li><Link to='https://www.instagram.com/lake.eildon.boat.sales/v'><Instagram color={'#000'} /></Link></li>
              </ul>
            </div>
            <div className='footer__col'>
              <span>Be the first to know about new boats for sale</span>
              <Newsletter />
            </div>
          </div>
        </div>
        <div className='footer__credits'>
          <p>Website by <Link to='https://design-digital.com.au'>Design Digital</Link></p>
        </div>
      </footer>
    )
  }
}

export default Footer
