import React, { Component } from 'react'

class Logo extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="119px" height="22px" viewBox="0 0 119 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" fontFamily="Eina01Regular" fontSize="28" fontWeight="normal" letterSpacing="-1.21333333">
          <g transform="translate(-65.000000, -65.000000)">
            <text id="Mara-+-Co">
              <tspan x="63.0526667" y="86" fill={color}>Mara</tspan>
              <tspan x="123.747333" y="86" letterSpacing="-3.08" fill={color} />
              <tspan x="127.947333" y="86" fill="#C64867">+</tspan>
              <tspan x="143.03" y="86" letter-spacing="-3.08" fill={color} />
              <tspan x="147.23" y="86" fill={color}>Co</tspan>
            </text>
          </g>
        </g>
      </svg>
    )
  }
}

class YouTube extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="23px" height="16px" viewBox="0 0 23 16" version="1.1">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-1355.000000, -67.000000)" fill={color}>
            <g id="/i/youtube" transform="translate(1355.000000, 67.000000)">
              <path d="M22.6349206,11.8191726 L22.6349206,3.84398525 C22.6349206,3.84398525 22.6349206,-2.61778903e-15 18.7342296,-2.61778903e-15 L3.89936015,-2.61778903e-15 C3.89936015,-2.61778903e-15 0,-2.61778903e-15 0,3.84398525 L0,11.8191726 C0,11.8191726 0,15.6631579 3.89936015,15.6631579 L18.7342296,15.6631579 C18.7342296,15.6631579 22.6349206,15.6631579 22.6349206,11.8191726 M15.7118928,7.84272664 L8.30177769,12.1313079 L8.30177769,3.55283386 L15.7118928,7.84272664"></path>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

class Instagram extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="19px" height="19px" viewBox="0 0 19 19" version="1.1">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-1313.000000, -66.000000)" fill={color}>
            <g id="/i/instagram" transform="translate(1313.000000, 66.000000)">
              <path d="M5.27741655,-3.55271368e-15 L13.008104,-3.55271368e-15 C15.9111902,-3.55271368e-15 18.2851777,2.34461691 18.2851777,5.20890427 L18.2851777,12.918678 C18.2851777,15.7836511 15.9111902,18.1275823 13.008104,18.1275823 L5.27741655,18.1275823 C2.37433032,18.1275823 0,15.7836511 0,12.918678 L0,5.20890427 C0,2.34461691 2.37430175,-3.55271368e-15 5.27741655,-3.55271368e-15 Z M9.09333313,4.16956335 C11.9144218,4.16956335 14.2036974,6.45883902 14.2036974,9.27992766 C14.2036974,12.1013591 11.9144218,14.390292 9.09333313,14.390292 C6.2715588,14.390292 3.98262598,12.1013591 3.98262598,9.27992766 C3.98262598,6.45883902 6.2715588,4.16956335 9.09333313,4.16956335 Z M9.09333313,5.89562698 C10.9614783,5.89562698 12.4776338,7.41146821 12.4776338,9.27992766 C12.4776338,11.1483871 10.9614497,12.6645712 9.09333313,12.6645712 C7.22453083,12.6645712 5.70868961,11.1484157 5.70868961,9.27992766 C5.70868961,7.41146821 7.22453083,5.89562698 9.09333313,5.89562698 Z M14.0373309,3.46126985 C14.4956032,3.46126985 14.8671351,3.8328018 14.8671351,4.29073122 C14.8671351,4.74900349 14.4956032,5.12053544 14.0373309,5.12053544 C13.5794015,5.12053544 13.2078695,4.74900349 13.2078695,4.29073122 C13.2078695,3.8328018 13.5794015,3.46126985 14.0373309,3.46126985 L14.0373309,3.46126985 Z M5.9091123,1.48075654 L12.3770653,1.48075654 C14.8057083,1.48075654 16.7919643,3.45721283 16.7919643,5.87239909 L16.7919643,12.3727226 C16.7919643,14.7878517 14.8057369,16.7639937 12.3770653,16.7639937 L5.9091123,16.7639937 C3.48046929,16.7639937 1.49389901,14.7878517 1.49389901,12.372694 L1.49389901,5.87234195 C1.49389901,3.45721283 3.48046929,1.48075654 5.9091123,1.48075654 Z"></path>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

class RightArrow extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="8px" height="13px" viewBox="0 0 8 13" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-717.000000, -1638.000000)" fill={color} fillRule="nonzero">
            <g transform="translate(0.000000, 1156.000000)">
              <g transform="translate(484.000000, 482.000000)">
                <g transform="translate(237.000000, 6.500000) rotate(270.000000) translate(-237.000000, -6.500000) translate(231.000000, 3.000000)">
                  <path d="M0.217735665,0.217948897 C0.508049883,-0.0726496325 0.978742233,-0.0726496325 1.26905645,0.217948897 L6,4.95352555 L10.7309435,0.217948897 C11.0212578,-0.0726496325 11.4919501,-0.0726496325 11.7822643,0.217948897 C12.0725785,0.508547427 12.0725785,0.979700734 11.7822643,1.27029927 L6.52566039,6.53205111 C6.23534617,6.82264963 5.76465382,6.82264963 5.47433961,6.53205111 L0.217735665,1.27029927 C-0.0725785549,0.979700734 -0.0725785549,0.508547427 0.217735665,0.217948897 Z"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

class Plus extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="28px" height="28px" viewBox="0 0 28 28" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-429.000000, -1448.000000)" fill={color}>
            <g transform="translate(429.000000, 1448.000000)">
              <polygon points="15.2283871 27.3135484 15.2283871 14.9574194 27.8554839 14.9574194 27.8554839 12.4103226 15.2283871 12.4103226 15.2283871 0 12.6812903 0 12.6812903 12.4103226 5.13424428e-14 12.4103226 5.13424428e-14 14.9574194 12.6812903 14.9574194 12.6812903 27.3135484"></polygon>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

class DownArrow extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="72px" height="72px" viewBox="0 0 72 72" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-1303.000000, -194.000000)">
            <g transform="translate(1301.982667, 191.882353)">
              <g transform="translate(36.882353, 38.000000) scale(1, -1) rotate(90.000000) translate(-36.882353, -38.000000)">
                <g transform="translate(36.133414, 38.000000) rotate(90.000000) translate(-36.133414, -38.000000) translate(0.000000, 2.235294)">
                  <polygon fill={color} fillRule="nonzero" points="32.7803808 0.100804851 39.4833504 0.100804851 39.4833504 67.1393494 32.7803808 67.1393494"></polygon>
                  <polygon fill={color} fillRule="nonzero" points="5.3203134 30.5541201 40.8719059 66.1057126 36.1305383 70.8470802 0.578945785 35.2954877"></polygon>
                  <polygon fill={color} fillRule="nonzero" points="66.9463675 30.5541201 71.6877351 35.2954877 36.1361426 70.8470802 31.394775 66.1057126"></polygon>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

class DropDownArrow extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="38px" height="38px" viewBox="0 0 38 38" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Drop-Down" transform="translate(-832.000000, -7.000000)">
            <g id="Group" transform="translate(832.000000, 7.000000)">
              <rect id="Button" fill={color} x="0" y="0" width="38" height="38" rx="19"></rect>
              <path d="M27.2928932,18.2928932 L27.1208568,18.1208568 C26.7299464,17.731213 26.0972696,17.7309298 25.7060105,18.1202235 L20.125,23.69125 L20.125,23.69125 L20.125,11 C20.125,10.4477153 19.6772847,10 19.125,10 L18.875,10 C18.3227153,10 17.875,10.4477153 17.875,11 L17.875,23.69125 L17.875,23.69125 L12.3039735,18.1102395 C11.9144323,17.7187336 11.2812675,17.7181667 10.8903938,18.1083413 C10.8901826,18.1085521 10.8899715,18.108763 10.8903932,18.1096068 L10.7071068,18.2928932 C10.3165825,18.6834175 10.3165825,19.3165825 10.7071068,19.7071068 L18.2928932,27.2928932 C18.6834175,27.6834175 19.3165825,27.6834175 19.7071068,27.2928932 L27.2928932,19.7071068 C27.6834175,19.3165825 27.6834175,18.6834175 27.2928932,18.2928932 Z" fill="#FFFFFF" fillRule="nonzero"></path>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}


class Facebook extends Component {

  render() {

    const { color } = this.props

    return (
            
      <svg width="9px" height="19px" viewBox="0 0 9 19" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-840.000000, -375.000000)" fill={color}>
            <g transform="translate(840.000000, 375.000000)">
              <path d="M5.86793103,5.43016393 L5.86793103,3.96819672 C5.86793103,3.28196721 6.33862069,3.10295082 6.71517241,3.10295082 L8.84896552,3.10295082 L8.84896552,-5.32907052e-15 L5.89931034,-5.32907052e-15 C2.63586207,-5.32907052e-15 1.91413793,2.29737705 1.91413793,3.78918033 L1.91413793,5.43016393 L3.8191672e-14,5.43016393 L3.8191672e-14,7.60819672 L3.8191672e-14,9.04032787 L1.91413793,9.04032787 L1.91413793,18.0806557 L5.71103448,18.0806557 L5.71103448,9.04032787 L8.53517241,9.04032787 L8.66068966,7.60819672 L8.88034483,5.40032787 L5.86793103,5.40032787 L5.86793103,5.43016393 Z" id="Path"></path>
            </g>
          </g>
        </g>
      </svg>

    )
  }

}

class Close extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="19px" height="19px" viewBox="0 0 19 19" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-1360.000000, -67.000000)" fill={color}>
            <g transform="translate(1357.500000, 64.970563)">
              <g transform="translate(12.000000, 12.000000) rotate(45.000000) translate(-12.000000, -12.000000) ">
                <polygon points="13.0529032 23.4116129 13.0529032 12.8206452 23.876129 12.8206452 23.876129 10.6374194 13.0529032 10.6374194 13.0529032 0 10.8696774 0 10.8696774 10.6374194 4.40078082e-14 10.6374194 4.40078082e-14 12.8206452 10.8696774 12.8206452 10.8696774 23.4116129"></polygon>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

class Phone extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g fill={color} fillRule="nonzero">
            <path d="M0.32686762,6.15611621 C0.32686762,6.15611621 -0.206548317,4.47547589 0.089454016,2.85566492 C0.32686762,1.29524448 1.15628589,0.695064327 1.39294962,0.515413945 C1.63036323,0.395202061 1.98572389,0.155553647 1.98572389,0.155553647 C1.98572389,0.155553647 2.4004429,-0.264416589 2.8745005,0.275765531 C3.3485581,0.755825724 5.54088951,3.03586684 5.54088951,3.03586684 C5.54088951,3.03586684 5.95560852,3.51592703 5.6002439,3.99600721 C5.24488323,4.41597744 3.99997636,5.67664752 3.99997636,5.67664752 C4.53339229,7.71714813 8.14795571,11.3772999 10.1627449,11.91745 C10.1627449,11.91745 11.4068822,10.7168899 11.8809398,10.3570296 C12.2956588,9.99716927 12.7697164,10.4171355 12.7697164,10.4171355 C12.7697164,10.4171355 15.0213075,12.5777361 15.5547037,13.0579362 C16.0287613,13.5981063 15.6140581,13.9579666 15.6140581,13.9579666 C15.6140581,13.9579666 15.4359949,14.3779368 15.2586974,14.5582467 C15.1399886,14.7986664 14.4886375,15.6385869 12.9477085,15.9383373 C11.4067993,16.1787571 9.68852551,15.6385869 9.68852551,15.6385869 C6.13255082,14.1983864 1.74737492,9.81763479 0.325182381,6.15668373 L0.32686762,6.15611621 Z"></path>
          </g>
        </g>
      </svg>
    )
  }
}


class Area extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="11px" height="9px" viewBox="0 0 11 9" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-1075.000000, -1961.000000)" fill={color} fillRule="nonzero">
            <g transform="translate(0.000000, 1156.000000)">
              <g transform="translate(1040.000000, 796.000000)">
                <g transform="translate(35.000000, 8.000000)">
                  <path d="M9,6 L8.2,6 L8.2,7.2 L7,7.2 L7,8 L9,8 L9,6 Z M2.8,3.8 L4,3.8 L4,3 L2,3 L2,5 L2.8,5 L2.8,3.8 Z M10,1 L1,1 C0.45,1 0,1.45 0,2 L0,9 C0,9.55 0.45,10 1,10 L10,10 C10.55,10 11,9.55 11,9 L11,2 C11,1.45 10.55,1 10,1 Z M10,9 L1,9 L1,2 L10,2 L10,9 L10,9 Z"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}


class Beds extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="14px" height="9px" viewBox="0 0 14 9" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-1110.000000, -1961.000000)" fill={color} fillRule="nonzero">
            <g transform="translate(0.000000, 1156.000000)">
              <g transform="translate(1040.000000, 796.000000)">
                <g transform="translate(70.000000, 6.000000)">
                  <path d="M4.05263158,8.68421053 C5.06578947,8.68421053 5.89473684,7.61842105 5.89473684,6.31578947 C5.89473684,5.01315789 5.06578947,3.94736842 4.05263158,3.94736842 C3.03947368,3.94736842 2.21052632,5.01315789 2.21052632,6.31578947 C2.21052632,7.61842105 3.03947368,8.68421053 4.05263158,8.68421053 Z M11.4545455,3.94736842 L6.36363636,3.94736842 L6.36363636,9.47368421 L1.27272727,9.47368421 L1.27272727,3.94736842 L2.27373675e-13,3.94736842 L2.27373675e-13,11.8421053 L14,11.8421053 L14,7.10526316 C14,5.36052632 12.8609091,3.94736842 11.4545455,3.94736842 Z"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}


class Baths extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-1144.000000, -1954.000000)" fill={color} fillRule="nonzero">
            <g transform="translate(0.000000, 1156.000000)">
              <g transform="translate(1040.000000, 796.000000)">
                <g transform="translate(104.000000, 0.000000)">
                  <g transform="translate(0.740741, 2.333333)">
                    <path d="M13.7566138,8.88888889 L13.7566138,2.35555556 C13.7566138,1.05555556 12.7513228,0 11.5132275,0 C10.9206349,0 10.3492063,0.244444444 9.92592593,0.688888889 L9.04761905,1.61111111 C8.86772487,1.52222222 8.65608466,1.47777778 8.44444444,1.47777778 C8.14814815,1.47777778 7.87301587,1.56666667 7.64021164,1.73333333 L9.68253968,3.87777778 C9.83068783,3.63333333 9.92592593,3.34444444 9.92592593,3.03333333 C9.92592593,2.81111111 9.87301587,2.6 9.7989418,2.4 L10.6772487,1.47777778 C10.8994709,1.24444444 11.1957672,1.11111111 11.5132275,1.11111111 C12.1693122,1.11111111 12.6984127,1.66666667 12.6984127,2.35555556 L12.6984127,8.88888889 L6.45502646,8.88888889 C6.24338624,8.73333333 6.05291005,8.55555556 5.87301587,8.35555556 L4.88888889,7.21111111 C4.75132275,7.05555556 4.58201058,6.92222222 4.4021164,6.83333333 C4.17989418,6.72222222 3.93650794,6.66666667 3.69312169,6.66666667 C2.82539683,6.66666667 2.11640212,7.41111111 2.11640212,8.33333333 L2.11640212,8.88888889 L0,8.88888889 L0,14.4444444 L1.05820106,14.4444444 L1.05820106,15.5555556 L13.7566138,15.5555556 L13.7566138,14.4444444 L14.8148148,14.4444444 L14.8148148,8.88888889 L13.7566138,8.88888889 Z"></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

class Arrow extends Component {
  render() {
    return (
      <svg width="61px" height="60px" viewBox="0 0 61 60" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-256.000000, -1386.000000)">
            <g transform="translate(0.000000, 1156.000000)">
              <g transform="translate(256.290323, 230.000000)">
                <rect stroke="#FFFFFF" x="0.5" y="0.5" width="59" height="59" rx="29.5"></rect>
                <path d="M43.2772656,29.1522656 C42.6017911,28.4767911 41.5067817,28.4763009 40.8307027,29.1511705 L32.359375,37.6073437 L32.359375,37.6073437 L32.359375,18.234375 C32.359375,17.2765061 31.5828689,16.5 30.625,16.5 C29.6671311,16.5 28.890625,17.2765061 28.890625,18.234375 L28.890625,37.6073437 L28.890625,37.6073437 L20.4431158,29.1446957 C19.7642461,28.4646093 18.6625945,28.4636231 17.9825082,29.1424928 C17.9821407,29.1428596 17.9817734,29.1432266 17.9814063,29.1435938 C17.3013194,29.8236806 17.3013194,30.9263194 17.9814063,31.6064062 L28.5036797,42.1286797 C29.6752525,43.3002525 31.5747475,43.3002525 32.7463203,42.1286797 L43.2772656,31.5977344 C43.9525632,30.9224368 43.9525632,29.8275632 43.2772656,29.1522656 Z" fill="#FFFFFF" fillRule="nonzero"></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

export { Logo, YouTube, Instagram, RightArrow, Plus, DownArrow, Close, Facebook, Phone, DropDownArrow, Area, Beds, Baths, Arrow }
